import { Quote } from '~/config/quotes'
import { QuoteBlock } from './quote-block'
import clsx from 'clsx'

type QuoteBlockProps = {
  quote: Quote
  className?: string
}

export const QuoteSection = ({ quote, className }: QuoteBlockProps) => {
  return (
    <section className={clsx(['quote-section', className])}>
      <div className="dot-texture"></div>
      <div className="dot-texture"></div>
      <QuoteBlock quote={quote} />
    </section>
  )
}
